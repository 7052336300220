@import "@vueform/slider/themes/default.scss";

a:hover, a:active, a:focus, a:visited {
    text-decoration: none;
}

.ais-InstantSearch {
    display: block;
    margin: 0 auto;
    max-width: 960px;
    position: relative;
    width: 100%;
}

.ais-Hits-list {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
    border: 1px solid #eee;
    list-style: none;
    padding: 15px;
    margin: 17px;
}

.ais-Hits-item {
    margin-left: 0;
    width: 100%;
}

.ais-Hits-item + .ais-Hits-item {
    border-top: 1px solid #eee;
    margin-top: 1em;
    padding-top: 1em;
}

.ais-Hits-item h1 {
    font-size: 15px;
    margin-bottom: 0;
}

.ais-Hits-item p {
    margin: 0;
}

.ais-Hits-item img {
    margin-right: 1em
}

.ais-Hits-item article {
    font-family: "Roboto", "sans-serif";
    height: 55px;
}

.search__input {
    box-shadow: 0 0 0 2px #e5e5e5 inset;
    height: 45px;
    width: 100% !important;
}

.ais-Hits-list {
    background: #fff;
    margin-bottom: 1em;
}

.ais-SearchBox-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
}

#desktop-hits {
    position: absolute;
}

.ais-SearchBox-reset {
    display: none;
}

.search--location--header .search__button {
    position: absolute;
    right: 0;
}

.ais-Hits-item small {
    color: darkorange;
    display: block;
    font-size: 12px;
    margin-top: 5px;
}

.ais-Hits-item .info_prod {
    font-size: 12px;
}

.ais-Hits-item a {
    color: #1b1e21;
}

.product__actions-item--addtocart .btn-lg {
    font-size: 0.85em;
}

#password-requirements {
    font-size: 0.85em;
}

#password-requirements li {
    color: red
}

.met {
    color: green !important;
}


.switch {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 25px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}

.switch::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked + .switch {
    background: #72da67;
}

input:checked + .switch::before {
    left: 47px;
    background: #fff;
}

input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

#tax_pdf {
    display: none;
}

.container_field_password {
    display: none;
}
